<template>
  <main>
    <section class="section" style="padding-bottom: 0;">
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
          <sqr-crumb name="settings" :bid="bid" label="breadcrumb_settings"/>
          <sqr-crumb name="settings-email" :bid="bid" label="breadcrumb_settings_email"/>
        </ul>
      </nav>
    </section>
    <sqr-error :error="loadError"/>
    <section class="section" v-if="!loadError">
      <div class="container">
        <div class="columns is-centered" v-if="record">
          <div class="column is-12-mobile is-8-tablet is-6-desktop">
            <div class="field">
              <!-- <sqr-input-text :value="record."/> -->
            </div>
            <div class="field">
              <sqr-input-text :value="record.defaultFrom" @change="fieldSet({field: 'defaultFrom', value: $event})"/>
            </div>
            <div class="field">
              <sqr-input-text :value="record.smtpConnectionUri" @change="fieldSet({field: 'smtpConnectionUri', value: $event})"/>
            </div>
            <div class="field is-pulled-right">
              <sqr-button label="buttons_save" @click="saveAndGo()" color="primary" :is-loading="saving"/>
            </div>
            <sqr-error-note :error="saveError"/>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import SqrInputText from '@/sqrd/SqrInputText';

export default {
  name: 'SettingsEmail',
  components: {SqrInputText},
  computed: {
    ...mapGetters('brokers', ['broker', 'bid']),
    ...mapGetters('settings', ['record', 'loading', 'loadError', 'saving', 'saveError'])
  },
  mounted () {
    this.sub({path: ['brokers', this.bid, 'settings'], id: 'email'});
  },
  methods: {
    ...mapMutations('settings', ['fieldSet']),
    ...mapActions('settings', ['sub', 'save']),
    async saveAndGo () {
      await this.save();
      this.$router.back();
    }
  }
}
</script>
